<template>
  <div>
    <v-list-item v-if="mini" class="mb-0 pa-0 logoListItem">
      <v-list-item-content class="pa-0">
        <v-img
          class="logo_sm"
          contain
          src="/img/fubon-logo-white-2.png"
        ></v-img>
      </v-list-item-content>
    </v-list-item>
    <v-list-item v-else class="mb-0 justify-space-between pl-3">
      <v-list-item-content class="px-2 pt-4">
        <v-img class="logo" contain src="/img/fubon-logo-white-1.png"></v-img>
      </v-list-item-content>
    </v-list-item>
  </div>
</template>

<script>
import { sync } from "vuex-pathify";
export default {
  name: "DefaultDrawerHeader",
  computed: {
    mini: sync("app/mini"),
  },
};
</script>
<style lang="scss" scoped>
@import "../../styles/main.scss";
.logo {
  max-width: 180px;
  max-height: 70px;
  margin: 0 auto;
  &_sm {
    // @include boxSize(20px);
    width: 20px;
    height: 20px;
  }
}
</style>
